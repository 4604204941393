import React, { useEffect, useState, useMemo, useContext } from 'react'
import './style.css'
import { Link, useNavigate } from 'react-router-dom';
import AnnouncementBar from '../../Component/AnnouncementBar';
import Navbar from '../../Component/Navbar';
import Banner from '../../Screens/Cart/Component/Banner';
import Footer from '../../Component/Footer';
import BestProduct from '../../Component/BestProduct';
import Placeholder from '../../../../asesst/placeholder.jpeg'
import { toast } from 'react-toastify';
import { createOrder } from '../../../../apis/orders';
import { ContentContext } from '../../../../store';
import OpenCart from '../../Component/OpenCart';
import { createSlug } from '../../../../helper';
function CheckoutOrder({ domain }) {
    const { products, domainInfo } = useContext(ContentContext)
    const [cartProducts, setCartProducts] = useState([]);
    const navigate = useNavigate();

    const [data, setData] = useState({});
    const handleChange = (event) => {
        const { name, value } = event.target;
        setData(prevData => ({ ...prevData, [name]: value }));
    };
    useEffect(() => {
        document.documentElement.style.fontSize = "62.5%";
        return () => {
            document.documentElement.style.fontSize = "16px";
        };
    }, []);

    useEffect(() => {
        const storedProducts = localStorage.getItem(`${domain}-carts`);
        let cartproducts = storedProducts ? JSON.parse(storedProducts) : [];
        setCartProducts(cartproducts)
    }, [])


    // Calculate subtotal using useMemo for performance optimization
    const subtotal = useMemo(() => {
        return cartProducts?.reduce((acc, product) => {
            return acc + (Number(product.retailPrice) * product.selectedQty);
        }, 0);
    }, [cartProducts]);

    const handleCheckout = async () => {
        try {
            // Validate required fields
            if (!data?.name) {
                return toast.error("Please enter your name");
            }
            if (!data?.address) {
                return toast.error("Please enter your address");
            }
            if (!data?.mobile) {
                return toast.error("Please enter your mobile number");
            }

            // Prepare product IDs for the order
            const products = cartProducts?.map(product => {
                return {
                    id: product?.id,
                    qty: product?.selectedQty,
                    amount: Number(product?.retailPrice) * product?.selectedQty,
                    variant: product?.selectedVariant
                }
            });
            // Send the create order request
            const response = await createOrder(domain, { ...data, products, amount: subtotal });

            // Handle success response
            if (response) {
                localStorage.removeItem(`${domain}-carts`);
                toast.success(response?.message);
                navigate(`/thank-you/${response?.order?.orderNumber}`)
            }
        } catch (error) {
            // Handle errors gracefully and log the exact issue
            const errorMessage = error.response?.data?.message || "An error occurred during checkout.";
            toast.error(errorMessage);
            console.error("Checkout Error: ", error);
        }
    };

    return (
        <>
            {domainInfo?.topNavbar && <AnnouncementBar note={domainInfo?.topNavbarNote} mobile={domainInfo?.topNavbarMobile} email={domainInfo?.topNavbarEmail} />}
            <Navbar domain={domain} logo={domainInfo?.logo} />
            <Banner lable='Check Out' />

            <div className='check_dtail_main'>

                {/* <div><h3 cl>sddcx</h3></div> */}
                <div className='ino_box_grpun '>
                    <h1 className='pt-5'>Customer Information</h1>
                    <div className='inp_group'>
                        <div className='full_width_inp'>
                            <input onChange={handleChange} name='name' type='text' placeholder='Name' />
                        </div>
                        <div className='full_width_inp'>
                            <input onChange={handleChange} name='address' type='text' placeholder='Address' />
                        </div>
                        <div className='inp_box'>
                            <input onChange={handleChange} name='mobile' type='number' placeholder='Mobile Number' />
                        </div>
                        <div className='inp_box'>
                            <input onChange={handleChange} name='email' type='email' placeholder='Email (Optional)' />
                        </div>

                        {/* <div className='checkbox_container'>
                                    <label><input type="checkbox" /> Save this information for next time </label>
                                </div> */}


                        {/* <div className='shipping_box'>
                                <div>
                                    Shipping
                                </div>
                                <div>
                                    Free
                                </div>
                            </div> */}

                        {/* <div>
                            <button className='btn_save'>Order Now</button>
                        </div> */}
                    </div>
                </div>
                <div>
                    <div className='Check_out_container'>
                        <div className='shipping__head'>
                            <div>
                                <p className='shipping_text'>Checkout</p>
                            </div>
                        </div>
                        <div className='px-4'></div>
                        <div className=''>
                            <table className=''>
                                <thead className='table_head'>
                                    <tr>
                                        <th className='text-start procut_table_heading'>
                                            Product
                                        </th>
                                        <th className='procut_table_heading'>
                                            Price
                                        </th>
                                        <th className='procut_table_heading'>
                                            Quantity
                                        </th>
                                        <th className='procut_table_heading'>
                                            Total
                                        </th>
                                    </tr>
                                </thead>
                                {
                                    cartProducts?.map((item, index) => (
                                        <tbody key={index} className='table_body'>
                                            <tr>
                                                <td>
                                                    <div className=' table_img_product '>
                                                        <div>
                                                            <Link
                                                                to={`/product/${createSlug(item?.itemname)}`}
                                                                className="hdt-page-cart__img hdt-block hdt-relative hdt-oh"
                                                                style={{ '--aspect-ratioapt': 1.0 }}
                                                            >
                                                                <img
                                                                    src={item?.image ?? Placeholder}
                                                                    alt=""
                                                                    width="70"
                                                                    height="700"
                                                                    loading="lazy"
                                                                    sizes="(max-width: 767px) 80px, 160px"
                                                                />
                                                            </Link>
                                                        </div>
                                                        <div className="product-info">
                                                            <Link
                                                                to={`/product/${createSlug(item?.itemname)}`}
                                                            >
                                                                <h5 className='product_details2'>{item?.itemname}</h5>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='product_prices_text'>
                                                    {`${item?.retailPrice ? Number(item?.retailPrice) : "00"}`}
                                                </td>
                                                <td className='product_add'>
                                                    <span className='product_prices_text'>
                                                        {item?.selectedQty}
                                                    </span>
                                                </td>
                                                <td className='product_prices_text'>
                                                    {`${item?.retailPrice && item?.selectedQty ? (Number(item.retailPrice) * Number(item.selectedQty)) : "00"}`}
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))
                                }
                            </table>
                        </div>
                        <div className='mt-3 d-flex align-items-center'>
                            <div>
                                <input placeholder='Coupon Code' className='apply_inp' />
                            </div>
                            <div>
                                <button className='apply__btn m-2'>Apply</button>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>
                                <p className='subTotal_items'>Subtotal - {cartProducts?.length} Items</p>
                            </div>
                            <div>
                                <p className='Subtotal_price_items text-dark fs-4'>{subtotal}
                                </p>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>
                                <p className='subTotal_items'>Shipping</p>
                            </div>
                            <div>
                                <p className='Subtotal_price_items text-dark fs-4'>Free</p>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>
                                <p className='subTotal_items'>Total</p>
                            </div>
                            <div>
                                <p className='Subtotal_price_items text-dark fs-4'>{subtotal} </p>
                            </div>
                        </div>
                        <div className='product_title'>
                            <div className='check_out_parent'>
                                <button onClick={handleCheckout} className='Check_out_button '>Place Order</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BestProduct initialVisible={3} products={products} domain={domain} />
            <Footer credit={domainInfo?.footerCredit} logo={domainInfo?.footerLogo} socialMedia={domainInfo?.socialMedia} isSocialMedia={domainInfo?.isSocialMedia} />
            <OpenCart domain={domain} initialProducts={products} />
        </>
    )
}

export default CheckoutOrder