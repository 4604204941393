import React from 'react';
import './PricingPlanCard.css';
import { CheckIcon } from '../icon';

const PricingPlanCard = ({ planTitle, originalPrice, discountedPrice, planDescription, features, additionalInfo }) => {
   
    const discountPercentage = ((originalPrice - discountedPrice) / originalPrice) * 100;

    return (
        <div className='sub_box_plan'>
            <h2 className='item_heading'>{planTitle}</h2>
            <h3 className='text-start'><span className='orignal_price_style'>PKR  <del>{originalPrice}</del></span> <span className="discount"><button className='btn btn-secondary btn__discount'>  {discountPercentage.toFixed(0)}% off</button></span></h3>

            <h1 className='headinf_price'>
                <span className='price__text_style'></span>
                {discountedPrice} PKR

            </h1>
            <button className="pricing_button">Choose Plan</button>
            <hr />
            <p>{planDescription}</p>
            <ul className='order_list_container py-3'>
                {features.map((feature, index) => (
                    <li key={index} className='pricing_list_item gap-2'>
                        <CheckIcon color='green' /> {/* Add checkmark icon */}
                        <span className='pricing_list_item_sub_heading'>{feature.title} </span>
                        <span>{feature.description}</span>
                    </li>
                ))}
            </ul>
            <p>{additionalInfo}</p>
        </div>
    );
};

export default PricingPlanCard;
