
import React, { createContext, useState, useEffect } from 'react';
import { fetchProducts } from '../apis/products';
import { fetchCategories } from '../apis/categories';
import { fetchDomain } from '../helper';
import { domainList } from '../constant';
import { fetchDomainInfo } from '../apis/domains';

const ContentContext = createContext();

const ContentProvider = ({ children }) => {

    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [storeName, setStoreName] = React.useState('');
    const [domainInfo, setDomainInfo] = React.useState('');
    const domain = fetchDomain();

    const fetch = async () => {
        try {
            if (!domainList.includes(domain)) {
                const products = await fetchProducts(domain);
                const categories = await fetchCategories(domain);
                const domainInfo = await fetchDomainInfo(domain);
                const name = products.map(store => store.storeName)[0];
                setDomainInfo(domainInfo)
                setStoreName(name);
                setProducts(products)
                setCategories(categories)
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        fetch()
    }, [])

    const values = {
        products,
        setProducts,
        categories,
        setCategories,
        storeName,
        setStoreName,
        domainInfo,
        setDomainInfo
    }

    return (
        <ContentContext.Provider value={{ ...values }}>
            {children}
        </ContentContext.Provider>
    );
};

export { ContentContext, ContentProvider };
