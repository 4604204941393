import React, { useContext } from "react";

import "./style.css";

import LeftNav from "./leftNav";
import RightNav from "./rightNav";
import SearchBar from "./searchBar";
import Logo from "./logo";
import { ContentContext } from "../../../../store";

function Navbar({ domain, logo }) {
  const { storeName } = useContext(ContentContext);
  return (
    <div>
      <div className="desktop_nav w-100">
        <Logo name={storeName} logo={logo} />
        <LeftNav />
        <SearchBar />
        <RightNav domain={domain} />
      </div>
      <div className="mobile_nav w-100">
        <LeftNav />
        <Logo />
        <RightNav />
      </div>
    </div>
  );
}

export default Navbar;
