import React from "react";
import { Link } from "react-router-dom";

const AnnouncementBar = ({ note, email, mobile }) => {
  return (
    <div className="hdt-top-bar hdt-s-gradient hdt-top-bar--bottom-border">
      <div className="hdt-container-full hdt-px-15 lg:hdt-px-40">
        <div className="hdt-top-bar__wrap hdt-row-grid hdt-grid-cols-1 md:hdt-grid-cols-1 lg:hdt-grid-cols-3 hdt-align-center">
          <div className="px-5 hdt-top-bar__item hdt-top-bar__custom-liquid hdt-hidden md:hdt-hidden lg:hdt-block">
            <span style={{ marginInlineEnd: "2.9rem" }}>{mobile}</span>
            <span>{email}</span>
          </div>

          <div className="hdt-top-bar__item hdt-top-bar__text hdt-text-center">
            <div
              autoheight=""
              className="hdt-top-bar-text__slider div lg:hdt-slides-1 hdt-relative hdt-oh"
            >
              <div className="div__viewport is-draggable">
                <div className="div__container announcement-middle">
                  <div className="div__slide hdt-top-bar-text__item hdt-rte is-selected is-in-view">
                    {note}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementBar;
