import React, { useState, useContext } from "react";
import { ContentContext } from "../../../../store";
import { useNavigate } from "react-router-dom";
import placeholder from '../../../../asesst/placeholder.jpeg'
import { createSlug } from "../../../../helper";

const SearchBar = () => {
  const [searchValue, setSearchValue] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const { products } = useContext(ContentContext); // Getting products from the context
  const navigate = useNavigate();
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);

    if (value === "") {
      setFilteredProducts([]);
    } else {
      let filtered = products.filter((product) =>
        product.itemname.toLowerCase().includes(value?.trim())
      );

      filtered = filtered?.map(product => {
        const price = product?.itemType === "Variants"
          ? Math.min(...product?.combinations?.map(combination => Number(combination?.retailPrice) || 0))
          : Number(product?.retailPrice);
        return {
          ...product,
          retailPrice: price
        }
      })
      setFilteredProducts(filtered);
    }
  };

  const handleNavigateProduct = (itemname) => {
    navigate(`/product/${createSlug(itemname)}`);
    window.location.reload()
  }

  return (
    <div className="search_section">
      <div className="search-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <g fill="none" fillRule="evenodd">
            <path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
            <path
              fill="currentColor"
              d="M10.5 2a8.5 8.5 0 1 0 5.262 15.176l3.652 3.652a1 1 0 0 0 1.414-1.414l-3.652-3.652A8.5 8.5 0 0 0 10.5 2M4 10.5a6.5 6.5 0 1 1 13 0a6.5 6.5 0 0 1-13 0"
            />
          </g>
        </svg>
      </div>
      <input
        type="text"
        className="search-input"
        placeholder="Search"
        value={searchValue}
        onChange={handleSearch}
      />

      {/* Display dropdown with filtered products */}
      {filteredProducts.length > 0 && (
        <div className="dropdown">
          {filteredProducts.map((product) => (
            <div key={product.id} className="dropdown-item" onClick={() => handleNavigateProduct(product.itemname)}>
              <img
                src={product.image || placeholder}
                alt={product.itemname}
                width="50"
              />
              <div>
                <h4>{product.itemname}</h4>
                <p>Price: {product.retailPrice}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
