import React, { useState } from "react";
import DropdownDesktop from "./dropdownDesktop";
import { Link } from "react-router-dom";
import DropdownMobile from "./dropdownMobile";
import { Category, SubCategory } from "../constant";

const LeftNav = () => {
  const [hide, setHide] = useState(false);
  const handleToggle = () => {
    setHide(!hide);
  };
  return (
    <div>
      {" "}
      <div className="menu-toggle " onClick={handleToggle}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M22 18.005c0 .55-.446.995-.995.995h-8.01a.995.995 0 0 1 0-1.99h8.01c.55 0 .995.445.995.995M22 12c0 .55-.446.995-.995.995H2.995a.995.995 0 1 1 0-1.99h18.01c.55 0 .995.446.995.995m-.995-5.01a.995.995 0 0 0 0-1.99H8.995a.995.995 0 1 0 0 1.99z"
          />
        </svg>
      </div>
      <div
        className={`dropdown_section px-5 ${hide ? "show-menu" : "hide-menu"} `}
      >
        {/* desktop  */}
        <div className="nav-tab">
          <Link to="/shop">
            Shop{" "}
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="0.88em"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7L86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                />
              </svg>
            </span>
          </Link>
          <DropdownDesktop
            
            subCategoryList={SubCategory}
            categoryList={Category}
          />
        </div>
        <div className="nav-tab category-tab ">
          <Link to="jdh">
            Categories{" "}
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="0.88em"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7L86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                />
              </svg>
            </span>
          </Link>
          <DropdownDesktop
           
            subCategoryList={SubCategory}
            categoryList={Category}
          />
        </div>
        {/* mobile  */}
        <div className="close-nav-icon d-large-none" onClick={handleToggle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"
            />
          </svg>
        </div>
        <div className="d-large-none ">
          <DropdownMobile
            title="Shop"
            link="/shop"
            subCategoryList={SubCategory}
            categoryList={Category}
          />
          <DropdownMobile
            title="Category"
            subCategoryList={SubCategory}
            categoryList={Category}
          />
          <button className="hdt-dark-btn me-2 hdt-align-center hdt-btn hdt-btn-solid hdt-button hdt-font-semibold ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.3em"
              height="1.3em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="m12.1 18.55l-.1.1l-.11-.1C7.14 14.24 4 11.39 4 8.5C4 6.5 5.5 5 7.5 5c1.54 0 3.04 1 3.57 2.36h1.86C13.46 6 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5c0 2.89-3.14 5.74-7.9 10.05M16.5 3c-1.74 0-3.41.81-4.5 2.08C10.91 3.81 9.24 3 7.5 3C4.42 3 2 5.41 2 8.5c0 3.77 3.4 6.86 8.55 11.53L12 21.35l1.45-1.32C18.6 15.36 22 12.27 22 8.5C22 5.41 19.58 3 16.5 3"
              />
            </svg>
            &nbsp; WishList
          </button>
          <button className="hdt-dark-btn hdt-align-center hdt-btn hdt-btn-solid hdt-button hdt-font-semibold ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.3em"
              height="1.3em"
              viewBox="0 0 24 24"
            >
              <g fill="none" fill-rule="evenodd">
                <path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                <path
                  fill="currentColor"
                  d="M10.5 2a8.5 8.5 0 1 0 5.262 15.176l3.652 3.652a1 1 0 0 0 1.414-1.414l-3.652-3.652A8.5 8.5 0 0 0 10.5 2M4 10.5a6.5 6.5 0 1 1 13 0a6.5 6.5 0 0 1-13 0"
                />
              </g>
            </svg>
            &nbsp;Search
          </button>
          <div className="mt-5">
            <span
              className="hdt-inline-flex hdt-text-base hdt-font-medium"
              style={{
                color: "rgb(var(--color-foreground))",
                borderBottom: "solid 1px rgb(var(--color-foreground))",
                marginBottom: 11,
              }}
            >
              <Link
                to="https://demo-ecomus-global.myshopify.com/pages/contact-1"
                aria-describedby="a11y-external-message"
              >
                Need help ?
              </Link>
            </span>

            <span className="hdt-flex hdt-flex-col hdt-text-base hdt-font-normal">
              <span>
                Address: 1234 Fashion Street, Suite 567,
                <br /> New York, NY 1000
              </span>
              <span>
                Email: <b>info@ecomus.com</b>
              </span>
              <span>
                Phone: <b>(212) 555-1234</b>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className={hide && "backdrop"} onClick={handleToggle}></div>
    </div>
  );
};

export default LeftNav;
