import React from "react";
import { Link } from "react-router-dom";
import BestProductCard from "../Cards/BestProductCard";

const DropdownDesktop = ({ categoryList, subCategoryList }) => {
  return (
    <div className="mega-menu">
      <div className="inner-mega-menu">
        <div className="list-section">
          {categoryList.map((i) => (
            <div className="list-item">
              <Link to="#" className="mega-heading">
                {i.categoryName}
              </Link>
              <ul>
                {subCategoryList.map(
                  (item) =>
                    item.categoryName === i.categoryName && (
                      <Link to="aaa">
                        <li> {item.subCategoryName} </li>
                      </Link>
                    )
                )}
              </ul>
            </div>
          ))}
        </div>
        <div className="card-section">
          <Link to="#" className="mega-heading">
            Best Seller
          </Link>
          <div className="d-flex">
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropdownDesktop;
