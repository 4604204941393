import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Placeholder from "../../../../../asesst/placeholder.jpeg";
import { createSlug } from "../../../../../helper";
const BestProductCard = ({
  product,
  src,
  link,
  domain,
  title,
  price,
  className,
}) => {
  const navigate = useNavigate();
  const [isInCart, setIsInCart] = useState(false);

  // Handle adding or removing the product from the cart
  const handleAddCart = async () => {
    const storedProducts = localStorage.getItem(`${domain}-carts`);
    let products = storedProducts ? JSON.parse(storedProducts) : [];

    // Check if the product is already in the cart
    const productIndex = products.findIndex((item) => item.id === product?.id);

    if (productIndex > -1) {
      // Product is in the cart, remove it
      products.splice(productIndex, 1);
    } else {
      // Product is not in the cart, add it
      products.push(product);
    }

    // Update localStorage
    localStorage.setItem(`${domain}-carts`, JSON.stringify(products));
    checkProductInCart(); // Re-check if product is in the cart after adding/removing
  };

  const checkProductInCart = () => {
    const carts = JSON.parse(localStorage.getItem(`${domain}-carts`)) || [];
    const productInCart = carts.some((item) => item.id === product?.id);
    setIsInCart(productInCart);
  };

  useEffect(() => {
    checkProductInCart();
  }, [product]);

  const handleWishList = async () => {
    console.log(product);
  };

  const handleView = async () => {
    navigate(`/product/${createSlug(product?.itemname)}`);
    window.location.reload();
  };

  return (
    <>
      <div
        className={`hdt-card-product__wrapper hdt-oh hdt-pr-img__effect-zoom ${className}`}
      >
        <div className="hdt-card-product__media hdt-relative ">
          <Link to={link} className="hdt-card-product__media-wrapp hdt-ratio">
            <img
              src={src ?? Placeholder}
              alt={title}
              width="795"
              height="795"
              loading="lazy"
              className="hdt-card-product__media--hover "
            />
          </Link>
          <div className="hdt-badge__wrapp hdt-pe-none">
            {/* <div className="hdt-badge hdt-badge__on-sale">Sale</div> */}
          </div>
        </div>
        <div className="hdt-card-product__info hdt-card-align-left">
          <Link
            to={link}
            className="hdt-card-product__title hdt-text-lg hdt-font-normal hdt-line-clamp"
            onClick={handleView}
          >
            {title}
          </Link>
          <div className="div-wrapp hdt-text-base hdt-font-semibold">
            <div className="div__list">
              <div className="div">
                {/* <span className="sr-only sr-only--inline">Sale price</span> */}
                <span className="hdt-money">{price}</span>
                {/* <span class="hdt-money ">$279.00</span> */}
              </div>
            </div>
          </div>
        </div>
        <div className="hdt-product-btns">
          {product?.itemType !== "Variants" && (
            <button
              type="button"
              onClick={handleAddCart}
              className="hdt-card-product__btn-ultra hdt-pr_btn"
            >
              {isInCart ? (
                // Check Icon
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M9 17.5l-5-5 1.4-1.4 3.6 3.6L18.6 7l1.4 1.4-11 11z"
                    clip-rule="evenodd"
                  />
                </svg>
              ) : (
                // Cart Icon
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M5 4h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1M2 5a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3zm10 7c-2.761 0-5-2.686-5-6h2c0 2.566 1.67 4 3 4s3-1.434 3-4h2c0 3.314-2.239 6-5 6"
                    clip-rule="evenodd"
                  />
                </svg>
              )}
              <span className="sr-only">Quick add</span>
            </button>
          )}

          {/* <button
            className="hdt-card-product__btn-wishlist hdt-pr_btn"
            role="button"

            onClick={handleAddWishlist}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="m12.1 18.55l-.1.1l-.11-.1C7.14 14.24 4 11.39 4 8.5C4 6.5 5.5 5 7.5 5c1.54 0 3.04 1 3.57 2.36h1.86C13.46 6 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5c0 2.89-3.14 5.74-7.9 10.05M16.5 3c-1.74 0-3.41.81-4.5 2.08C10.91 3.81 9.24 3 7.5 3C4.42 3 2 5.41 2 8.5c0 3.77 3.4 6.86 8.55 11.53L12 21.35l1.45-1.32C18.6 15.36 22 12.27 22 8.5C22 5.41 19.58 3 16.5 3" /></svg>
            <span className="sr-only">Add to Wishlist</span>
          </button> */}

          <button
            type="button"
            onClick={handleView}
            className="hdt-card-product__btn-quick-view hdt-pr_btn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <g
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              >
                <path d="M15 12a3 3 0 1 1-6 0a3 3 0 0 1 6 0" />
                <path d="M2 12c1.6-4.097 5.336-7 10-7s8.4 2.903 10 7c-1.6 4.097-5.336 7-10 7s-8.4-2.903-10-7" />
              </g>
            </svg>
            <span className="sr-only">Quick view</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default BestProductCard;
