import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ imageUrl, imageAlt, title, link }) => {
  return (
    <div className="hdt-collection-item hdt-collection-style6 hdt-slider__slide is-selected is-in-view">
      <div className="hdt-collection-inner" calc-nav="">
        <div className="">
          <span>
            <img
              src={imageUrl}
              alt={imageAlt}
              width="124"
              height="125"
              loading="lazy"
              style={{
                width: "100%",
                height: "175px",
                borderRadius: "100%",
              }}
            />
            {/* <img src={imageUrl} className="images" /> */}
          </span>
          <Link className="hdt-full-width-link" to={link}></Link>
        </div>
        <div className="hdt-collection-content hdt-text-end-center">
          <Link
            className="hdt-collection-title hdt-truncate hdt-inline-flex hdt-align-center hdt-justify-center hdt-text-xl hdt-font-medium"
            to={link}
          >
            <span className="hdt-collection-title-text hdt-truncate">
              {title}
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
