import React from 'react';
import CustomNavbar from '../../Component/Navber';
import LetsStart from '../../Component/LetsStart';
import Footer from '../../Component/Footer';
import './style.css'
function AboutUs() {
    return (
        <>
            <CustomNavbar />
            <div className='heading_container'>
                <h1 className='main_heading' >About Us</h1>
            </div>
            <div className='content_container'>
                <p className='content'>
                    Welcome to Tijarah Soft, where innovation meets simplicity in business management. We are a dynamic team of professionals dedicated to empowering businesses with the tools they need to thrive in today's competitive landscape. Our mission is to simplify business operations through cutting-edge software solutions that are easy to use, reliable, and tailored to meet the unique needs of our customers.                </p>
                <h2 className='item_heading'>Who We Are</h2>
                <p>Tijarah Soft is more than just a software company; we are your partners in success. Our team comprises industry experts, software engineers, and customer support specialists who are passionate about creating solutions that make a difference. With a deep understanding of the challenges businesses face, we strive to develop software that not only addresses these challenges but also helps our customers grow and succeed.</p>
                <h2 className='item_heading'>Our Vision</h2>
                <p>We envision a world where businesses of all sizes can access the tools they need to manage their operations efficiently, without the complexity and cost that often comes with enterprise-level software. Our goal is to democratize business technology, making it accessible, affordable, and scalable for everyone.</p>
                <h2 className='item_heading'>Our Mission</h2>
                <p>Our mission is to provide powerful, user-friendly software solutions that streamline business operations, improve productivity, and enable growth. We are committed to continuous innovation, ensuring that our products evolve with the changing needs of our customers and the market.</p>
                <h2 className='item_heading'>What We Offer</h2>
                <p>Tijarah Soft offers a comprehensive suite of business management tools designed to simplify your daily operations. From inventory management and point-of-sale (POS) systems to customer relationship management (CRM) and accounting software, our products are designed to integrate seamlessly into your business, allowing you to focus on what you do best—running your business.</p>
                <h2 className='item_heading'>Why Choose Us?</h2>
                <ul className='order_list_container'>
                    <li className='list_item'><span className='list_item_sub_heading'>User-Friendly Design:</span> <span>Our software is designed with the user in mind, featuring intuitive interfaces and easy-to-navigate features.</span></li>
                    <li className='list_item'><span className='list_item_sub_heading'>Customizable Solutions:</span> <span>We understand that every business is unique, so our solutions are highly customizable to meet your specific needs.</span></li>
                    <li className='list_item'><span className='list_item_sub_heading'>Exceptional Support:</span> <span>Our dedicated support team is always ready to assist you with any questions or issues you may encounter.</span></li>
                    <li className='list_item'><span className='list_item_sub_heading'>Continuous Improvement:</span> <span>We are committed to regularly updating our software to incorporate the latest technologies and best practices.</span></li>
                </ul>
                <h2 className='item_heading'>Our Commitment to You</h2>
                <p>At Tijarah Soft, we believe that our success is measured by your success. We are committed to providing exceptional service, reliable software, and a partnership you can trust. Whether you are a small business just starting or an established enterprise looking to optimize your operations, Tijarah Soft is here to help you achieve your goals.</p>
                <p>Thank you for choosing Tijarah Soft. We look forward to being a part of your business journey.</p>
                <h2 className='item_heading'>Contact Us</h2>
                <p>We would love to hear from you! Whether you have a question, need support, or want to learn more about how Tijarah Soft can help your business, feel free to reach out to us:</p>

                <h3 className='brand_name'>Tijarah Soft</h3>
                <a href='mailto:support@tijarahsoft.com' className='email_link'>support@tijarahsoft.com</a>
                <p>Together, let's build a brighter future for your business.</p>
            </div>
            <LetsStart />
            <Footer />
        </>
    )
}

export default AboutUs;