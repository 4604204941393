import React, { useState } from 'react'
import './style.css'
import cart from '../../../../asesst/cart.png'
import RightSideModal from '../RigthSIteModal'
import ProductTableModalContent from '../RigthSIteModal/Tablemodal'
import { CartIcon } from '../../../../component/icon'
function OpenCart({ domain, initialProducts }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <div className='cart_postion' onClick={() => setIsModalOpen(true)} >
            <button className="open-modal-button">
                <CartIcon />
            </button>
            <RightSideModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <ProductTableModalContent domain={domain} initialProducts={initialProducts} />
            </RightSideModal>
        </div>
    )
}

export default OpenCart