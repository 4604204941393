import React from 'react'
import "./style.css"
const Banner = ({lable}) => {
  return (
    <div><div
      className="hdt-heading-template h section-template--22004517896512__heading hdt-relative"
      color-scheme="scheme-1"
    >
      <div className="hdt-heading-banner hdt-full-width-link">

      </div>
      <div className="hdt-heading-full-page hdt-container">
        <div className="hdt-container">
          <div className="hdt-main-heading hdt-flex hdt-justify-center hdt-flex-col hdt-text-center hdt-align-center hdt-spacing_hd hdt-relative">
            <h1 className="heading__banner text-white">
             {lable}
            </h1>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Banner