import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";

const DropdownMobile = ({ categoryList, subCategoryList, title ,link}) => {
  return (
    <div>
      <Accordion className="mobile-drop">
        <Accordion.Item eventKey={title}>
          <Accordion.Header><Link to={link}>{title}</Link></Accordion.Header>
          <Accordion.Body>
            {categoryList.map((i) => (
              <Accordion className="mobile-sub-drop">
                <Accordion.Item eventKey={i.id}>
                  <Accordion.Header>
                    <Link to="hh">{i.categoryName}</Link>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      {subCategoryList.map(
                        (item) =>
                          item.categoryName === i.categoryName && (
                            <Link to="aaa">
                              <li> {item.subCategoryName} </li>
                            </Link>
                          )
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default DropdownMobile;
