import React from 'react'
import './style.css'
import { FacebookIcon, LinkinIcon, TwiterIcon } from '../../../../component/icon';
import Logo from '../../../../asesst/logo.png'

function Footer() {
    return (
        <div className='main_footer'>
            <div className='compelet__screen_perfect main_sec_footer'>
                <div className='footer_flex'>
                    <div className='px-5 d-flex-justify-content-center'>
                        <img src={Logo} alt='Tijarah Soft' className='home_logo' />
                        <div className='fotter__icons'>
                            <div>
                                <a href='https://www.facebook.com/tijarahsoft' target='_blank'>
                                    <FacebookIcon />
                                </a>
                            </div>
                            <div>
                                <a href='https://www.linkedin.com/company/tijarah-soft/' target='_blank'>
                                    <LinkinIcon />
                                </a>
                            </div>
                        </div>

                    </div>
                    <div>
                        <ul className=' navigation__list'  >
                            <h4 className='container_title'>Navigation</h4>
                            <li className='links_item'><a href='/'>Home</a></li>
                            <li className='links_item'><a href='/about-us'>About Us</a></li>
                            <li className='links_item'><a href='/features'>Features</a></li>
                            <li className='links_item'><a href='/pricing-plans'>Pricing Plans</a></li>
                        </ul>
                    </div>
                    <div>
                        <ul className='links_container'>
                            <h4 className='container_title'>Useful Links</h4>
                            <li className='links_item'><a href='/service-policy'>Service Policy</a></li>
                            <li className='links_item'><a href='/refund-policy'>Refund Policy</a></li>
                            <li className='links_item'><a href='/privacy-policy'>Privacy Policy</a></li>
                            <li className='links_item'><a href='/terms-and-conditions'>Terms and Conditions</a></li>
                        </ul>
                    </div>
                    <div className='contact_box'>
                        <h4 className='container_title'>Contact Us</h4>
                        <p className='sec_pera'>+92 342 4652710</p>
                        <p className='sec_pera'>+92 317 2988830</p>
                        <p className='sec_pera'>support@tijarahsoft.com</p>
                        <p className='sec_pera'>N-A Zaman Town Korangi Karachi Pakistan</p>
                    </div>

                </div>
                <p className='  last_text_footer'>© 2024 .Tijarah Soft</p>
            </div>
        </div>
    )
}

export default Footer;
