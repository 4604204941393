import React, { useState, useRef } from 'react';
import './style.css';
import profile from '../../../../asesst/profile.png';
import menprofile from '../../../../asesst/menprofile.png';
import mahmood from '../../../../asesst/mahmood.jpeg'
import ayesha from '../../../../asesst/ayesha.jpeg'
import shah from '../../../../asesst/shah.jpeg'
import usman from '../../../../asesst/usman.jpeg'
import ali from '../../../../asesst/ali.jpeg'
import hina from '../../../../asesst/hima.jpeg'
import iqbal from '../../../../asesst/iqbal.jpeg'
const testimonials = [
    {
        img: profile,
        name: 'Fatima Ali',
        feedback: 'Tijarah Softs software is perfect for our business needs. Its intuitive design and reliable performance have streamlined our operations. Highly recommended!',
    },
    {
        img: menprofile,
        name: 'Bilal Ahmed',
        feedback: 'Tijarah Soft has provided us with a tool that makes managing our business easy. The quality of thesoftware and the support teams assistance have met our expectations.',
    },
    {
        img: ayesha,
        name: 'Ayesha Khan',
        feedback: 'Tijarah Soft ka POS software ne hamare operations ko streamline kar diya hai. Yeh software user-friendly aur efficient hai. Hamari team ab zyada productive hai. Shukriya Tijarah Soft!',
    },
    {
        img: mahmood,
        name: 'Samiullah Shah',
        feedback: 'Tijarah Soft ne hamare business ke liye ek reliable solution provide kiya hai. Is software ka use karke hamne apne processes ko bahut behtar aur organized banaya hai',
    },
    {
        img: shah,
        name: 'Sarah Mahmood',
        feedback: 'Tijarah Softs software has been a game-changer for us. Its comprehensive functionality and easy-to-useinterface have perfectly met our business needs',
    },
    {
        img: usman,
        name: 'Usman Tariq',
        feedback: 'Tijarah Softs team understood our business requirements and provided a solution that makes our dailyoperations easier. We highly appreciate their recommendations.'
    },
    {
        img: ali,
        name: 'Ali Raza',
        feedback: 'Tijarah Soft ka POS solution hamare business ke liye ideal hai. Iska user-friendly design aur reliable performance ne hamare kaam ko behad asaan bana diya hai'
    },
    {
        img: hina,
        name: 'Hina Javed',
        feedback: 'Tijarah Soft s software covers every aspect of our business. It is highly customizable and efficient,fulfilling all our business needs.'
    },
    {
        img: iqbal,
        name: 'Hassan Iqbal',
        feedback: 'Tijarah Softs software has streamlined our operations and increased our teams productivity We are very pleased with its features and support.'
    },
    // Add more testimonials as needed
];

function AboutCards() {
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const sliderRef = useRef(null);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - sliderRef.current.offsetLeft);
        setScrollLeft(sliderRef.current.scrollLeft);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - sliderRef.current.offsetLeft;
        const walk = (x - startX) * 3;
        sliderRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleDotClick = (index) => {
        const cardWidth = sliderRef.current.children[0].offsetWidth;
        sliderRef.current.scrollTo({
            left: cardWidth * index,
            behavior: 'smooth',
        });
        setActiveIndex(index);
    };

    const updateActiveIndex = () => {
        const cardWidth = sliderRef.current.children[0].offsetWidth;
        const currentIndex = Math.round(sliderRef.current.scrollLeft / cardWidth);
        setActiveIndex(currentIndex);
    };

    return (
        <div className='main_card_about'>
            <div>
                <h3 className='customer_about'>
                    Hear <span>from Our</span> Satisfied Clients
                </h3>
            </div>
            <div
                className="carousel"
                ref={sliderRef}
                onMouseDown={handleMouseDown}
                onMouseLeave={handleMouseLeave}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                onScroll={updateActiveIndex}
            >
                {testimonials.map((testimonial, index) => (
                    <div key={index} className='cards'>
                        <div className="profile_img">
                            <img src={testimonial.img} alt={`${testimonial.name} profile`} className='cards_profile' />
                        </div>
                        <h3 className='cards_head'>{testimonial.name}</h3>
                        <p className='cards_pera'>{testimonial.feedback}</p>
                    </div>
                ))}
            </div>
            <div className='dots'>
                {testimonials.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${activeIndex === index ? 'active' : ''}`}
                        onClick={() => handleDotClick(index)}
                    />
                ))}
            </div>
        </div>
    );
}

export default AboutCards;
