import React from "react";
import BestProductCard from "../Cards/BestProductCard";
import ScrollAnimation from 'react-animate-on-scroll';
import { useNavigate } from "react-router-dom";
import { createSlug } from "../../../../helper";

function BestProduct({ products, domain, initialVisible = 8, heading }) {
  const [visibleProducts, setVisibleProducts] = React.useState(initialVisible);
  const navigate = useNavigate();

  const loadMoreProducts = () => {
    setVisibleProducts(prevCount => prevCount + 4);
  };

  return (
    <div className="screen-spacing hdt-pr-discount2 px-lg-5 best-product-container text-center m-5">
      <div className="hdt-section-main-heading hdt-flex hdt-justify-center hdt-align-center hdt-text-center hdt-relative">
        <ScrollAnimation animateIn="fadeIn">
          <h3 className="hdt-sec-heading hdt-h4 hdt-flex hdt-align-center">
            {heading}
          </h3>
        </ScrollAnimation>
      </div>
      <div className="d-flex justify-content-center w-100">
        <div className="row hdt-pr-discount2 justify-content-center">
          {products?.slice(0, visibleProducts)?.map((product, index) => {
            const price = product?.itemType === "Variants"
              ? Math.min(...product?.combinations?.map(combination => Number(combination?.retailPrice) || 0))
              : Number(product?.retailPrice);
            return (
              <BestProductCard
                key={index}
                src={product?.image}
                link={`/product/${createSlug(product?.itemname)}`}
                title={product?.itemname}
                domain={domain}
                product={product}
                price={price?.toFixed()}
                onClick={() => navigate('/checkout')}
                className="best-Product-card"
              />
            );
          })}
        </div>
      </div>
      {
        visibleProducts < products?.length && (
          <button
            onClick={loadMoreProducts}
            className="hdt-dark-btn hdt-align-center hdt-btn hdt-button hdt-font-semibold my-5"
          >
            Load More Products
          </button>
        )
      }
    </div>
  );
}

export default BestProduct;
