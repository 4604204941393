export const Category = [
  { id: "c1", categoryName: "Clothing" },
  { id: "c2", categoryName: "Edible" },
  { id: "c3", categoryName: "Accessories" },
  { id: "c4", categoryName: "Other" },
];
export const SubCategory = [
  { subCategoryName: "Baby", categoryName: "Clothing" },
  { subCategoryName: "Girl", categoryName: "Clothing" },
  { subCategoryName: "Boy", categoryName: "Clothing" },
  { subCategoryName: "Ready to Eat", categoryName: "Edible" },
  { subCategoryName: "Ready to Cook", categoryName: "Edible" },
  {
    subCategoryName: "Electronics ",
    categoryName: "Accessories",
  },
  {
    subCategoryName: "Kitchen Appliances",
    categoryName: "Accessories",
  },

  {
    subCategoryName: "etc",
    categoryName: "Other",
  },
];
