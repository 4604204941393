import React from 'react';
import CustomNavbar from '../../Component/Navber';
import LetsStart from '../../Component/LetsStart';
import Footer from '../../Component/Footer';
import './style.css'
function Features() {
    return (
        <>
            <CustomNavbar />
            <div className='heading_container'>
                <h1 className='main_heading' >Features of Tijarah Soft</h1>
            </div>
            <div className='content_container'>
                <p className='content'>
                    Tijarah Soft is designed to streamline your business operations with a comprehensive suite of powerful, user-friendly features. Whether you're managing inventory, handling sales, or analyzing business performance, Tijarah Soft provides the tools you need to succeed.
                </p>
                <ol className='order_list_container'>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Point of Sale (POS) System</h2>
                        <p>Our intuitive POS system is at the heart of Tijarah Soft, enabling you to process sales quickly and efficiently. With an easy-to-use interface, real-time transaction tracking, and integrated payment options, you can manage sales seamlessly, whether you're in-store or on the go.</p>
                        <ul>
                            <li><span className='list_item_sub_heading'>Quick Checkout:</span> <span>Speed up transactions with fast and reliable checkout processes.</span></li>
                            <li><span className='list_item_sub_heading'>Multiple Payment Methods:</span> <span>Accept cash, credit cards, mobile payments, and more.</span></li>
                            <li><span className='list_item_sub_heading'>Sales Reporting:</span> <span>Gain insights into your sales data with detailed reports.</span></li>
                        </ul>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Inventory Management</h2>
                        <p>Keep track of your stock levels effortlessly with Tijarah Soft's robust inventory management features. Monitor inventory in real time, automate reordering, and reduce stockouts to ensure you always have the products your customers want.</p>
                        <ul>
                            <li><span className='list_item_sub_heading'>Real-Time Inventory Tracking:</span> <span>Know what's in stock at all times.</span></li>
                            <li><span className='list_item_sub_heading'>Automated Reordering:</span> <span>Set thresholds to automatically reorder products.</span></li>
                            <li><span className='list_item_sub_heading'>Inventory Reports:</span> <span>Analyze stock levels, turnover rates, and more.</span></li>
                        </ul>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Customer Relationship Management (CRM)</h2>
                        <p>Build and maintain strong relationships with your customers using our integrated CRM tools. Manage customer profiles, track purchase history, and tailor your marketing efforts to improve customer loyalty and drive repeat business.</p>
                        <ul>
                            <li><span className='list_item_sub_heading'>Customer Profiles:</span> <span>Store detailed customer information and preferences.</span></li>
                            <li><span className='list_item_sub_heading'>Purchase History:</span> <span>Track past transactions to offer personalized service.</span></li>
                            <li><span className='list_item_sub_heading'>Loyalty Programs:</span> <span>Reward your customers and encourage repeat purchases.</span></li>
                        </ul>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Multi-Store Management</h2>
                        <p>Manage multiple stores from a single platform with ease. Tijarah Soft allows you to oversee operations, track sales, and manage inventory across all your locations, ensuring consistency and efficiency.</p>
                        <ul>
                            <li><span className='list_item_sub_heading'>Centralized Control:</span> <span>Manage all your stores from one dashboard.</span></li>
                            <li><span className='list_item_sub_heading'>Inventory Transfers:</span> <span>Move stock between stores effortlessly.</span></li>
                            <li><span className='list_item_sub_heading'>Store-Specific Reports:</span> <span>Analyze performance at each location.</span></li>
                        </ul>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Accounting and Financial Management</h2>
                        <p>Stay on top of your finances with Tijarah Soft's built-in accounting features. Track expenses, manage invoices, and generate financial reports to keep your business financially healthy and compliant.</p>
                        <ul>
                            <li><span className='list_item_sub_heading'>Expense Tracking:</span> <span>Monitor and categorize business expenses.</span></li>
                            <li><span className='list_item_sub_heading'>Invoice Management:</span> <span>Create, send, and track invoices with ease.</span></li>
                            <li><span className='list_item_sub_heading'>Financial Reports:</span> <span>Generate balance sheets, profit and loss statements, and more.</span></li>
                        </ul>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>User Access Control</h2>
                        <p>Maintain security and control over your business operations with customizable user access levels. Assign roles and permissions to ensure that your team members have the appropriate access to the tools they need.</p>
                        <ul>
                            <li><span className='list_item_sub_heading'>Role-Based Access:</span> <span>Define roles with specific permissions.</span></li>
                            <li><span className='list_item_sub_heading'>Activity Logs:</span> <span>Monitor user activity for accountability.</span></li>
                            <li><span className='list_item_sub_heading'>Secure Login:</span> <span>Protect your data with secure authentication methods.</span></li>
                        </ul>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Offline Mode</h2>
                        <p>Tijarah Soft ensures uninterrupted business operations, even when the internet is down. Our offline mode allows you to continue processing transactions and managing inventory, with automatic syncing when the connection is restored.</p>
                        <ul>
                            <li><span className='list_item_sub_heading'>Seamless Offline Operation:</span> <span>Continue working without internet access.</span></li>
                            <li><span className='list_item_sub_heading'>Automatic Syncing:</span> <span>Sync data automatically when you're back online.</span></li>
                            <li><span className='list_item_sub_heading'>Reliable Performance:</span> <span>Ensure smooth operations no matter the connectivity.</span></li>
                        </ul>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Customizable Dashboards</h2>
                        <p>Get a clear overview of your business with customizable dashboards. Track key metrics, monitor sales, and stay informed with real-time data presented in a way that makes sense to you.</p>
                        <ul>
                            <li><span className='list_item_sub_heading'>Real-Time Analytics:</span> <span>Access up-to-date information at a glance.</span></li>
                            <li><span className='list_item_sub_heading'>Custom Widgets:</span> <span>Tailor your dashboard to display the data that matters most.</span></li>
                            <li><span className='list_item_sub_heading'>Visual Reports:</span> <span>Make informed decisions with easy-to-understand charts and graphs.</span></li>
                        </ul>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Comprehensive Reporting</h2>
                        <p>Make data-driven decisions with Tijarah Soft's powerful reporting tools. Generate detailed reports on sales, inventory, customer behavior, and financial performance to gain insights that drive your business forward.</p>
                        <ul>
                            <li><span className='list_item_sub_heading'>Sales Reports:</span> <span>Analyze trends, top-selling products, and revenue.</span></li>
                            <li><span className='list_item_sub_heading'>Inventory Reports:</span> <span>Track stock levels, reorder points, and inventory turnover.</span></li>
                            <li><span className='list_item_sub_heading'>Financial Reports:</span> <span>Monitor your business's financial health with ease.</span></li>
                        </ul>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Secure Cloud Backup</h2>
                        <p>Protect your business data with Tijarah Soft's secure cloud backup solution. Ensure your information is safe, accessible, and recoverable in the event of hardware failure or other disruptions.</p>
                        <ul>
                            <li><span className='list_item_sub_heading'>Automated Backups:</span> <span>Schedule regular backups to the cloud.</span></li>
                            <li><span className='list_item_sub_heading'>Data Encryption:</span> <span>Safeguard your data with industry-standard encryption.</span></li>
                            <li><span className='list_item_sub_heading'>Easy Recovery:</span> <span>Restore your data quickly and easily when needed.</span></li>
                        </ul>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>Scalable Solutions</h2>
                        <p>Whether you're a small business or growing enterprise, Tijarah Soft scales with you. Customize features, add users, and expand your operations effortlessly as your business evolves.</p>
                        <ul>
                            <li><span className='list_item_sub_heading'>Flexible Plans:</span> <span>Choose the plan that fits your business needs.</span></li>
                            <li><span className='list_item_sub_heading'>User Management:</span> <span>Easily add or remove users as your team grows.</span></li>
                            <li><span className='list_item_sub_heading'>Modular Features:</span> <span>Add or remove features based on your business requirements.</span></li>
                        </ul>
                    </li>
                    <li className='list_item'>
                        <h2 className='list_item_heading_trems'>24/7 Customer Support</h2>
                        <p>We understand that your business doesn't stop, and neither do we. Tijarah Soft offers 24/7 customer support to assist you whenever you need help, ensuring that any issues are resolved quickly and efficiently.</p>
                        <ul>
                            <li><span className='list_item_sub_heading'>Dedicated Support Team:</span> <span>Get help from knowledgeable professionals.</span></li>
                            <li><span className='list_item_sub_heading'>Multiple Support Channels:</span> <span>Reach us via phone, email, or live chat.</span></li>
                            <li><span className='list_item_sub_heading'>Comprehensive Help Center:</span> <span>Access tutorials, guides, and FAQs anytime.</span></li>
                        </ul>
                    </li>
                </ol>
                <p className='content'>
                    Experience the power of Tijarah Soft and take your business to the next level. Whether you're a small shop or a growing enterprise, our software provides the tools you need to streamline operations, increase productivity, and achieve your business goals. Start today and see how Tijarah Soft can transform the way you work.
                </p>
            </div>
            <LetsStart />
            <Footer />
        </>
    )
}

export default Features;