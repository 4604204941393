import { Box } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import Features from "./Component/Features";
import "./index.css";
import AnnouncementBar from "./Component/AnnouncementBar";
import Navbar from "./Component/Navbar";
import HeroSection from "./Component/HeroSection";
import BestProduct from "./Component/BestProduct";
import CategorySilder from "./Component/CategorySilder";
import Footer from "./Component/Footer";
import { ContentContext } from "../../store";
import { createVisitor } from "../../apis/views";
import { fetchDomain } from "../../helper";
import MetaTags from 'react-meta-tags';
import OpenCart from "./Component/OpenCart";

function Home({ domain }) {
  const { products, categories, domainInfo } = useContext(ContentContext)
  useEffect(() => {
    document.documentElement.style.fontSize = "62.5%";
    return () => {
      document.documentElement.style.fontSize = "16px";
    };
  }, []);

  const createStoreVisitor = async () => {
    try {
      await createVisitor({ domain: fetchDomain() })
    } catch (error) {

    }
  }

  useEffect(() => {
    createStoreVisitor();
  }, [])
  useEffect(() => {
    if (domainInfo?.tawkTo) {
      // Parse and insert the script into the document
      const scriptTag = document.createElement('div');
      scriptTag.innerHTML = domainInfo.tawkTo; // Assuming `tawkTo` contains the HTML with the script
      const scriptElement = scriptTag.querySelector('script');

      // Execute the script if available
      if (scriptElement) {
        const newScript = document.createElement('script');
        newScript.type = 'text/javascript';
        newScript.async = true;
        newScript.innerHTML = scriptElement.innerHTML; // Set script content
        document.body.appendChild(newScript);

        // Clean up the script when component unmounts
        return () => {
          document.body.removeChild(newScript);
        };
      }
    }
  }, [domainInfo?.tawkTo]);

  return (
    <>
      <MetaTags>
        <title>{domainInfo?.storeName}</title>
      </MetaTags>
      <Box className="store">
        {domainInfo?.topNavbar && <AnnouncementBar note={domainInfo?.topNavbarNote} mobile={domainInfo?.topNavbarMobile} email={domainInfo?.topNavbarEmail} />}
        <Navbar domain={domain} logo={domainInfo?.logo} />
        {domainInfo?.heroSection &&
          <HeroSection heading={domainInfo?.heroSectionHeading}
            subHeading={domainInfo?.heroSectionSubHeading}
            buttonText={domainInfo?.heroSectionButtonText}
            buttonUrl={domainInfo?.heroSectionButtonLink}
            image={domainInfo?.heroSectionImage} />}

        <CategorySilder categories={categories} />
        {domainInfo?.isBanners && <Features features={domainInfo?.banners} />}
        <BestProduct products={products} domain={domain} heading={domainInfo?.productsHeading} />
        <Footer credit={domainInfo?.footerCredit} logo={domainInfo?.footerLogo} socialMedia={domainInfo?.socialMedia} isSocialMedia={domainInfo?.isSocialMedia} />
        <OpenCart domain={domain} initialProducts={products} />
      </Box>
    </>
  );
}

export default Home;
