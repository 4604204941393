import React, { useEffect } from "react";
import Banner from "./Component/Banner";
import ProductTable from "./Component/ProductTable";
import Navbar from "../../Component/Navbar";
import Footer from "../../Component/Footer";
import AnnouncementBar from "../../Component/AnnouncementBar";
import EmptyCart from "./Component/EmptyCart";
import OpenCart from "../../Component/OpenCart";
import { ContentContext } from "../../../../store";
import { useContext } from "react";

const Cart = ({ domain }) => {
  const { domainInfo } = useContext(ContentContext)
  let products = localStorage.getItem(`${domain}-carts`);

  // Safely parse products or default to an empty array if there's an issue
  const parsedProducts = products ? JSON.parse(products) : [];

  useEffect(() => {
    document.documentElement.style.fontSize = "62.5%";
    return () => {
      document.documentElement.style.fontSize = "16px";
    };
  }, []);

  return (
    <div className="cart">
      {domainInfo?.topNavbar && <AnnouncementBar note={domainInfo?.topNavbarNote} mobile={domainInfo?.topNavbarMobile} email={domainInfo?.topNavbarEmail} />}
      <Navbar domain={domain} logo={domainInfo?.logo} />
      <Banner lable="Shopping Cart" />
      <div className="mt-2">
        {parsedProducts.length === 0 ? (
          <EmptyCart />
        ) : (
          <ProductTable initialProducts={parsedProducts} domain={domain} />
        )}
      </div>
      <div className="mt-3">
        <Footer credit={domainInfo?.footerCredit} logo={domainInfo?.footerLogo} socialMedia={domainInfo?.socialMedia} isSocialMedia={domainInfo?.isSocialMedia} />
        <OpenCart domain={domain} initialProducts={products} />
      </div>
    </div>
  );
};

export default Cart;
