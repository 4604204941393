import React from "react";
import { Link } from "react-router-dom";
// import "animate.css/animate.compat.css"
import ScrollAnimation from 'react-animate-on-scroll';
import Placeholder from '../../../../../asesst/placeholder.jpeg'
const BannerCard = ({
  id,
  imageUrl,
  heading,
  subheading,
  buttonLink,
  buttonText,
}) => {
  return (
    <div
      id={id}
      className="hdt-banner__item  
       hdt-col hdt-snap-center"
    >
      <div style={{ backgroundColor: !imageUrl ? "#000000" : "" }} className="hdt-item_inner hdt-rounded hdt-rounded-f hdt-media-overlap-content hdt-media-overlap-content-banner">
        {
          imageUrl && <picture className="hdt-media-wrapper">
            <img
              src={imageUrl ?? Placeholder}
              alt=""
              width="690"
              height="887"
              loading="lazy"
              sizes="(min-width: 1150px) calc((1440px - (15px * 2)) * 0.33), (min-width: 768px) calc((100vw - 30px - (15px * 2)) * 0.33), calc((100vw - (15px * -0.25)) * 1.33 - 30px)"
              className="hdt-object-pos-mb"
            />
          </picture>
        }
        <div className="hdt-grid hdt-h-full hdt-pe-none">
          <div className="hdt-place-self-end-center md:hdt-place-self-end-center hdt-pe-none">
            <div className="hdt-content_wrap hdt-oh hdt-pe-none">
              <ScrollAnimation animateIn='fadeIn'>
                <div className="hdt-content hdt-grid hdt-text-center md:hdt-text-center">
                  <p className="hdt-subheading hdt-s-text2 hdt-base hdt-font-bold">
                    {subheading}
                  </p>
                  <h3
                    className="hdt-heading hdt-s-text hdt-h5 hdt-font-semibold banner-heading"
                    hdt-reveal="slide-in"
                  >
                    {heading}
                  </h3>
                  <div>
                    <Link
                      to={buttonLink}
                      className="hdt-btn mt-4 hdt-text-base hdt-oh hdt-inline-flex hdt-align-center hdt-justify-center hdt-relative hdt-btn-solid hdt-font-semibold"
                    >
                      <span>{buttonText}</span>
                    </Link>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerCard;
