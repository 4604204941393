import React from 'react';
import './style.css'
import { Link, useNavigate } from 'react-router-dom';
const ProductTableModalContent = () => {
    const navigate = useNavigate(); // Initialize useNavigate
    const nextChecout = ()=>{
        navigate('/checkout');

    }
    const handleViewCart = () => {
        navigate('/cart');
    };
    return (
        <>
            <h3 className='p-2 py-3 fw-bold fs-3'>Add Cart</h3>

            <table className='tabe_style'>
                <tr>
                    <th className='cart__table'>
                        Product
                    </th>
                    <th className='cart__table'>
                        Price
                    </th>
                    <th className='cart__table'>
                        Quantity
                    </th>
                    <th className='cart__table'>
                        Total
                    </th>
                </tr>
                <tr>
                    <td>
                        <div className='d-flex'>
                            <div>
                                <img src='https://up.yimg.com/ib/th?id=OIP.wMftsrP6USIHg4aMEpwnPQHaHa&pid=Api&rs=1&c=1&qlt=95&w=107&h=107' width='50px' />
                            </div>
                            <div >
                                <p className='text_dark'>    dscx
                                </p>
                            </div>
                        </div>
                    </td>
                    <td className='cart__table'>
                        95.00$
                    </td>
                    <td className='cart__table'>
                        <div className='d-flex justify-content-between Table_bg_info'>
                            <div>
                                +
                            </div>
                            <div>
                                dsdsd
                            </div>
                            <div>
                                -
                            </div>
                        </div>
                    </td>
                    <td className='cart__table'>
                        555.00$
                    </td>
                </tr>
            </table>
            <div className='main_checkout_modal'>
                <h3 className='p-2 py-3 fw-bold fs-3'>Check Out</h3>
                <table className='tabe_style'>
                    <tr>
                        <th className='cart__table'>
                            Product
                        </th>
                        <th className='cart__table'>
                            Price
                        </th>
                        <th className='cart__table'>
                            Quantity
                        </th>
                        <th className='cart__table'>
                            Total
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <div className='d-flex gap-2'>
                                <div>
                                    <img src='https://up.yimg.com/ib/th?id=OIP.wMftsrP6USIHg4aMEpwnPQHaHa&pid=Api&rs=1&c=1&qlt=95&w=107&h=107' width='50px' />
                                </div>
                                <div >
                                    <p className='text_dark'>
                                        dscx
                                    </p>
                                </div>
                            </div>
                        </td>
                        <td className='cart__table'>
                            95.00$
                        </td>
                        <td className='cart__table'>
                            12
                        </td>
                        <td className='cart__table'>
                            555.00$
                        </td>
                    </tr>
                </table>
                <div className='d-flex gap-3 p-3'>
                    <div>
                        <button className='view_cart_btn' onClick={handleViewCart}>
                            View Cart
                        </button>                    </div>
                    <div>
                        <button onClick={nextChecout} className='view_cart_btn chech_out_btn'>Check Out</button>
                    </div>
                </div>
            </div>
        </>

    );
};

export default ProductTableModalContent;
