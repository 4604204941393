import {
  Box
} from "@chakra-ui/react";
import React from "react";
import About from "./Component/About";
import AboutCards from "./Component/AboutCard";
import Slider from "./Component/Cardslider";
import Features from "./Component/Features";
import Footer from "./Component/Footer";
import HeroSection from "./Component/HeroSection";
import LetsStart from "./Component/LetsStart";
import CustomNavber from "./Component/Navber";
import OurCLient from "./Component/OurCllient";
import CardUser from "./Component/userCard";
import "./index.css";
import Count from "./Component/countdown";
function Home() {
  // const handleClick = () => {
  //   window.open('https://wa.me/923172988830', '_blank');
  // };
  return (
    <Box>
      {/* <div onClick={handleClick} style={{ width: "55px", height: "55px", backgroundColor: "#ff8d7b", display: "flex", justifyContent: 'center', alignItems: "center", right: "20px", borderRadius: "50%", position: "fixed", bottom: "45px" }}>
        <Icon icon="logos:whatsapp-icon" style={{ fontSize: "32px" }} />
      </div>
      {" "} */}
      <CustomNavber />
      <HeroSection />
      <About />
      <Count/>
      <Features />
      <CardUser />
      <Slider />
      <OurCLient />
      <AboutCards />
      <LetsStart />
      <Footer/>
    </Box>
  );
}

export default Home;
