export const fetchDomain = () => {
  const hostname = window.location.hostname;
  // const hostname = "modamort";
  const domain = hostname.split(".")[0];
  return domain;
};
export function createSlug(title) {
  return title
    ?.toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');
}

export function decodeSlug(slug) {
  return slug
    .replace(/-/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());
}