import React from "react";
import BannerCard from "../Cards/BannerCard";

function Features({ features }) {
  return (
    <div className="screen-spacing  featcure__design">
      <div className="">
        <div
          className="hdt-banner_wrap hdt-row-flex hdt-flex-cols-1 md:hdt-flex-cols-3 lg:hdt-flex-cols-3 hdt-height--fixed md:hdt-height--fixed hdt-scroll-snap hdt-snap-x sm:hdt-snap-flex"
        >
          {
            features?.map((feature, index) => {
              return (
                <BannerCard
                  id={feature?.id}
                  imageUrl={feature?.image}
                  heading={feature?.heading}
                  subheading={feature?.subHeading}
                  buttonLink={feature?.buttonUrl}
                  buttonText={feature?.buttonText}
                  animationOrder={index?.toString()}
                />
              )
            })
          }
        </div>
      </div>
    </div>
  );
}

export default Features;
