import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentContext } from '../../../../store';
import Banner from '../Cart/Component/Banner';
import Placeholder from '../../../../asesst/placeholder.jpeg';
import AnnouncementBar from '../../Component/AnnouncementBar';
import BestProduct from '../../Component/BestProduct';
import Footer from '../../Component/Footer';
import Navbar from '../../Component/Navbar';
import './style.css';
import { createView } from '../../../../apis/views';
import { decodeSlug, fetchDomain } from '../../../../helper';
import { toast } from 'react-toastify';
import OpenCart from '../../Component/OpenCart';

function Product({ domain }) {
    const { products, domainInfo } = useContext(ContentContext);
    const [product, setProduct] = useState({}); // Initial quantity (2 in cart)
    const { title } = useParams();
    const name = decodeSlug(title)
    const [selectedVatiant, setSelectedVatiant] = useState('');
    const [selectedPrice, setSelectedPrice] = useState(0);

    const createPageView = async () => {
        try {
            await createView({ domain: fetchDomain(), productId: product?.id })
        } catch (error) {

        }
    }

    useEffect(() => {
        document.documentElement.style.fontSize = "62.5%";
        return () => {
            document.documentElement.style.fontSize = "16px";
        };
    }, []);

    useEffect(() => {
        const productData = products?.find(product => product.itemname?.toLowerCase()?.includes(name?.toLowerCase()));
        console.log("productData ==>", products)
        const price = product?.itemType === "Variants"
            ? Math.min(...product?.combinations?.map(combination => Number(combination?.retailPrice) || 0))
            : Number(product?.retailPrice);
        setSelectedPrice(price)
        setProduct(productData)
        createPageView();
        if (product?.itemType === "Variants") {
            const selectedVariant = product?.combinations?.find(combination => Number(combination?.retailPrice) === price);
            setSelectedVatiant(selectedVariant?.variantName)
        }
    }, [products])

    const [isInCart, setIsInCart] = useState(false);

    // Handle adding or removing the product from the cart
    const handleAddCart = async () => {
        const storedProducts = localStorage.getItem(`${domain}-carts`);
        let products = storedProducts ? JSON.parse(storedProducts) : [];

        // Check if the product is already in the cart
        const productIndex = products.findIndex(item => item.id === product?.id);

        if (productIndex > -1) {
            // Product is in the cart, remove it
            products.splice(productIndex, 1);
        } else {
            if (product?.itemType === "Variants") {
                product.retailPrice = selectedPrice;
                product.selectedVariant = selectedVatiant;
            }
            products.push(product);
        }
        if (product?.itemType === "Variants") {
            if (selectedVatiant && selectedPrice) {
                localStorage.setItem(`${domain}-carts`, JSON.stringify(products));
            } else {
                toast.error("Select Variant")
            }
        } else {
            localStorage.setItem(`${domain}-carts`, JSON.stringify(products));
        }
        checkProductInCart(); // Re-check if product is in the cart after adding/removing
    };


    const checkProductInCart = () => {
        const carts = JSON.parse(localStorage.getItem(`${domain}-carts`)) || [];
        const productInCart = carts.some(item => item.id === product?.id);
        setIsInCart(productInCart);
    };

    useEffect(() => {
        checkProductInCart();
    }, [product]);

    const handleChangeVariant = (variant) => {
        const selectedCombination = product?.combinations?.find(combination => combination?.variantName?.includes(variant?.label));
        if (selectedCombination) {
            const variantParts = selectedCombination?.variantName.split('-');
            const selectedIndex = variantParts.findIndex(v => v === variant?.label);
            if (selectedIndex !== -1) {
                setSelectedVatiant(variantParts.join('-'));
            }
            setSelectedPrice(selectedCombination?.retailPrice ? Number(selectedCombination?.retailPrice) : "");
        }
    }

    return (
        <>
            {domainInfo?.topNavbar && <AnnouncementBar note={domainInfo?.topNavbarNote} mobile={domainInfo?.topNavbarMobile} email={domainInfo?.topNavbarEmail} />}
            <Navbar domain={domain} logo={domainInfo?.logo} />
            <Banner lable={name} />
            <div className="product_main_container">
                <div className='  container_box '>
                    <img
                        src={product?.image ?? Placeholder}
                        alt="Product"
                        width='600px'
                        height='600px'
                    />
                </div>
                <div className='add_main_box  container_box'>
                    <h1 className='product_main_heading'>{product?.itemname}</h1>
                    <h1 className='product_main_heading pt-3 amout_size'>{selectedPrice}</h1>
                    <p className='product_text_title' >
                        {product?.description}
                    </p>
                    {
                        product?.variants?.map((variant, index) => {
                            return (
                                variant?.variantOptionName?.toLowerCase() === "color" ?
                                    < div className='color_selector mt-4' key={index} >
                                        <label htmlFor='color' className='selec_color' >
                                            Select Color: <span style={{ fontWeight: 'bold', marginLeft: '5px', fontSize: 16 }}>{selectedVatiant}</span>
                                        </label>
                                        <div className='color_options ml-2' style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px' }}>
                                            {variant?.variantValues?.map((color, index) => (
                                                <span
                                                    key={index}
                                                    className={`color_circle ${selectedVatiant === color?.label ? 'active' : ''}`}
                                                    onClick={() => handleChangeVariant(color)}
                                                    style={{
                                                        backgroundColor: color?.label, // Assuming color names match CSS color names
                                                        width: '30px',
                                                        height: '30px',
                                                        borderRadius: '50%',
                                                        cursor: 'pointer',
                                                        border: '2px solid #ccc' // Optional: border for better visibility
                                                    }}
                                                ></span>
                                            ))}
                                        </div>
                                    </div> :
                                    <div className='size_selector mt-5'>
                                        <label htmlFor='size' style={{ fontSize: 14 }}>
                                            Select: <span style={{ fontWeight: 'bold', marginLeft: '5px', fontSize: 16 }}>{selectedVatiant}</span>
                                        </label>
                                        <div className='size_options ml-2' style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', marginTop: '10px' }}>
                                            {variant?.variantValues?.map((size, index) => (
                                                <span
                                                    key={index}
                                                    onClick={() => handleChangeVariant(size)}
                                                    className={`size_box ${selectedVatiant === size?.label ? 'active' : ''}`}
                                                    style={{
                                                        padding: '5px 10px', // Smaller padding for smaller boxes
                                                        border: '1px solid #ccc',
                                                        borderRadius: '5px',
                                                        cursor: 'pointer',
                                                        backgroundColor: selectedVatiant === size?.label ? '#f0f0f0' : 'white',
                                                        fontSize: '14px' // Smaller font size
                                                    }}
                                                >
                                                    {size?.label?.charAt(0)}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                            )
                        })
                    }

                    <div className='mt-5 d-flex w-100'>
                        <div>
                            <button onClick={handleAddCart} className='add_cart_btn' >
                                {isInCart ? "Added" : "Add To Cart"}
                            </button>
                        </div>
                        <div>
                            {/* <button onClick={handleAddCart} className='buy_now_btn'>
                                Buy it Now
                            </button> */}
                        </div>
                    </div>
                </div>

            </div >
            <BestProduct initialVisible={3} products={products} domain={domain} />
            <Footer credit={domainInfo?.footerCredit} logo={domainInfo?.footerLogo} socialMedia={domainInfo?.socialMedia} isSocialMedia={domainInfo?.isSocialMedia} />
            <OpenCart domain={domain} initialProducts={products} />
        </>
    );
}

export default Product;
